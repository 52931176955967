* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
}
body {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    /* letter-spacing: -1px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /**This exists so when loading pages, there is no white in background */
    background: #292f35;
    height: 100vh;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
}
h1 {
    font-size: 4rem;
}
h2 {
    font-size: 3.6rem;
}
h3 {
    font-size: 3.2rem;
}
h4 {
    font-size: 2.8rem;
}
h5 {
    font-size: 2.4rem;
}

p {
    font-size: 2rem;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
